import { Link, useNavigate } from "react-router-dom";
import { useLayoutContext } from "..";
import logo from "assets/images/logo/logo-white.png";
import { SearchFilterPost } from "components/Filters";
import { Container, Modal, ModalHeader } from "reactstrap";
import { useAuthValue } from "shared/AuthContext";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Button } from "reactstrap";
import mailIcon from "assets/images/icons/mail-icon.svg";
import closeIcon from "assets/images/icons/close.svg";

function VerifyModal(props) {
  const {
    resendTimer,
    active = false,
    disabled = false,
    resend = () => {},
    email = "",
    close = () => {},
  } = props;
  return (
    <Modal
      isOpen={active}
      //  toggle={close}
      centered
    >
      <div className="pt-2 pe-2">
        <Button className="d-block ms-auto" onClick={close}>
          <img src={closeIcon} />
        </Button>
      </div>
      <div className="text-center pb-5">
        <img src={mailIcon} className="ms-3" alt="verify-email" />
        <h4>Verify your Email Address</h4>
        <p>A verification email has been sent to {email}</p>
        <Button color="primary" onClick={resend} disabled={disabled}>
          Resend Email
        </Button>

        <p className="mt-2 mb-0" style={{ fontSize: ".8em" }}>
          You can Resend in {resendTimer} seconds
        </p>
      </div>
    </Modal>
  );
}

function Menus(props) {
  const { menus } = props;

  // console.log('Menu',props)

  return (
    <ul className="head-menus">
      {menus.map(({ name, click, link }, index) => (
        <li key={"menus" + index}>
          {click ? <button onClick={click}>{name}</button> : <Link to={link}>{name}</Link>}
        </li>
      ))}
    </ul>
  );
}

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const { searchHeader = false } = useLayoutContext();
  const { currentUser, userConfig, logout, sendEmailVerificationMail } = useAuthValue();
  const [sendOn, setSendOn] = useState(false);
  const [verifyEmailModal, setVerifyEmailModal] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  const enableResendHandler = () => {
    setSendOn(true);
    let temp = 59;

    setInterval(() => {
      if (temp > 0) {
        temp -= 1;
        setResendTimer(temp);
      }
    }, 1000);
    setTimeout(() => {
      setSendOn(false);
    }, 60000);
  };

  const sendVerifyEmailFn = () => {
    if (!sendOn) {
      enableResendHandler();

      const sendEmail = sendEmailVerificationMail();
      sendEmail
        .then(() => {
          console.log(currentUser?.emailVerified);
        })
        .catch((error) => {
          console.log("errr", error);
        });
    }
  };

  const handleSendVerifyEmailFn = () => {
    setVerifyEmailModal(true);
    sendVerifyEmailFn();
  };

  const logoutHandler = async () => {
    try {
      await logout();
      navigate("/Login");
    } catch (error) {
      alert("Error in Logout");
      console.log(error.message);
      setError(error.message);
    }
  };

  function rightOptions() {
    let userMenu = [
      { name: "Home", link: "/" },
      { name: "Profile", link: "/profile" },
    ];
    if (userConfig && userConfig?.profileType) {
      if (userConfig?.profileType === "freelancer") {
        userMenu = [...userMenu, { name: "About Us", link: "/about-us" }];
      }
      if (userConfig?.profileType === "client") {
        userMenu = [
          { name: "Home", link: "/" },
          { name: "About Us", link: "/about-us" },
          { name: "Company Profile", link: "/profile" },
          { name: "Post Job", link: "/post-job" },
        ];
      }
    }

    return currentUser ? (
      <>
        <Menus menus={[...userMenu, { name: "Sign Out", click: () => logoutHandler() }]} />
      </>
    ) : (
      <>
        <Menus
          menus={[
            { name: "About Us", link: "/about-us" },
            { name: "Sign In", link: "/login" },
          ]}
        />
      </>
    );
  }

  return (
    <>
      {/* <pre>{JSON.stringify({ currentUser }, null, 2)}</pre> */}
      <header className="main-header">
        <div>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="menu-wrapper">{rightOptions()}</div>
      </header>

      {location?.pathname !== "/verify-email" && currentUser ? (
        <>
          {currentUser?.emailVerified ? (
            <></>
          ) : (
            <>
              <VerifyModal
                email={currentUser?.email}
                resendTimer={resendTimer}
                active={verifyEmailModal}
                disabled={sendOn}
                resend={sendVerifyEmailFn}
                close={() => setVerifyEmailModal(false)}
              />
              <div className="text-center p-2">
                <p style={{ color: "var(--bs-red)" }} className="mb-2">
                  Please Verify Your Email
                </p>
                <p>
                  <Button primary={"color"} disabled={sendOn} onClick={handleSendVerifyEmailFn}>
                    Verify Now{" "}
                  </Button>
                </p>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}

      {location?.pathname !== "/profile-type" && (
        <>
          {currentUser?.emailVerified ? (
            userConfig && userConfig?.profileType ? (
              <></>
            ) : (
              <div className="text-center p-2">
                <p style={{ color: "var(--bs-red)" }} className="mb-2">
                  Please Setup profile type
                </p>
                <p>
                  <Link to="/profile-type">Click</Link> here to go setup profile type
                </p>
              </div>
            )
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
