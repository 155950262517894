import { db } from "firebase-config";
import { DB_FREELANCER_PROFILE,DB_COMPANY_PROFILE,DB_JOB_POST } from "shared/Keys";
import { setDoc, doc, updateDoc ,getDoc,arrayUnion} from "firebase/firestore";

export const applyJobApi = async(jobId,profileId,profileType,jobCompanyId)=>{
  // console.log('Data Services id',id)
  console.log('Data Services profileId',profileId)

  const dbName = (profileType === "client") ? DB_COMPANY_PROFILE : (profileType === "freelancer") ?  DB_JOB_POST : false
  const  setDocumentId= (profileType === "client") ? profileId : (profileType === "freelancer") ?  jobId : false

  const payloadFreelancer = {
    "profileId":profileId, // freelancer Profile id
    "status":'applied',
  }
  const payloadJobId = {
    "jobPostId": jobId, // CompanyProfile id
    "status":'applied',
  }
  //Client
  const freelancerProfilePayload = {
    companyProfileId:profileId,
    "status":'applied',
    
  }
  const clientPayload = {
    "profileId":jobId, // freelancer Profile id
    "status":'applied',
    
  }


const payload= (profileType === "freelancer") ? payloadFreelancer : (profileType === "client") ?  true :false


  if (dbName && setDocumentId && payload){

    if (profileType === "freelancer") {
        // Update with existing User
    const appliedJobRef = doc(db, dbName,setDocumentId);
    const appliedJobPost = await updateDoc(appliedJobRef, { appliedUsers: arrayUnion(payload)});
    const freelancerProfile = doc(db, DB_FREELANCER_PROFILE,profileId);
    const appliedJob = await updateDoc(freelancerProfile, { appliedUsers: arrayUnion(payloadJobId)});
    }
    else if (profileType === "client") {

      const appliedJobRef = doc(db, dbName,setDocumentId);
      const appliedJobPost = await updateDoc(appliedJobRef, { appliedCompany: arrayUnion(clientPayload)});
      const freelancerProfile = doc(db, DB_FREELANCER_PROFILE,jobId);
      const appliedJob = await updateDoc(freelancerProfile, { appliedCompany: arrayUnion(freelancerProfilePayload)});
    }

}
}
