import cx from "classnames";
import dollarBagIcon from "assets/images/icons/dollar-bag.svg";
import bagIcon from "assets/images/icons/bag.svg";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState, useEffect } from "react";
import { useAuthValue } from "shared/AuthContext";

import { RightModal } from "./Modal";
function TypeCategories({ icon, value, className = "" }) {
  return (
    <p
      className={cx(
        "d-flex rounded-3 bg-light-gray-100 me-2 py-2 px-3 text-gray-dark font-weight-500",
        className
      )}
    >
      <span className="d-flex align-baseline">
        {icon && <img src={icon} className="me-2" style={{ maxHeight: 20 }} alt={value} />}
      </span>
      <span>{value}</span>
    </p>
  );
}
export default function CardMainPost(props) {
  // console.log('CardMainPost',props)
  const {
    logo,
    title,
    jobType,
    company,
    description,
    yourName,
    jobCategory,
    price,
    companyId,
    maxJobMoney,
    minJobMoney,
    id: jobId,
  } = props;
  const employPay = price || minJobMoney ? `$${minJobMoney} - $${maxJobMoney}` : false;
  const { currentUser, userConfig, storingJobIdSelected, setStoringJobIdSelected } = useAuthValue();
  const profileType = userConfig?.profileType;
  // console.log('description',description)

  const args = {
    fullscreen: true,
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (currentUser && storingJobIdSelected === jobId) {
      toggle();
      setStoringJobIdSelected("");
    }
    // console.log('JobId',jobId)
    return () => {};
  }, []);

  return (
    <div>
      <div className="card-main-post p-4">
        <div className="d-flex">
          {logo && (
            <div className="mb-3 icon-wrapper">
              <img src={logo} alt={title || yourName} />
            </div>
          )}
          <div className="flex-grow-1">
            <h3 className="title">{title || yourName}</h3>
            <p className="company text-gray">{company || jobCategory}</p>
            <div className="d-flex flex-wrap">
              {employPay && <TypeCategories icon={dollarBagIcon} value={employPay} />}
              {jobType && <TypeCategories icon={bagIcon} value={jobType} />}
              {jobCategory && <TypeCategories icon={bagIcon} value={jobCategory} />}
            </div>
          </div>
          <div>
            <Button color="primary-light btn-apply" onClick={toggle}>
              Apply
            </Button>
          </div>

          {/* <div >
            <Button>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </Button>
          </div> */}
        </div>
        <p style={{ whiteSpace: "no-wrap" }} className="text-gray-100">
          {typeof description === "string" && description?.length > 300
            ? `${description?.substring(0, 254)}...`
            : description}
        </p>
      </div>
      <div>
        <RightModal
          isOpen={modal}
          toggle={toggle}
          args={args}
          userData={props}
          profileType={profileType}
          jobCompanyId={companyId}
          freelancerId={userConfig?.freelancerId}
          companyId={userConfig?.companyId}
        />
      </div>
    </div>
  );
}
