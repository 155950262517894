import { doc, getDoc } from "firebase/firestore";
import { DB_COMPANY_PROFILE,DB_FREELANCER_PROFILE } from "shared/Keys";
import { db } from "firebase-config";


export const getProfileUserData = async (userConfig) => {
  const { profileType, freelancerId, companyId } = userConfig;

  const dbName = (profileType === "client") ? DB_COMPANY_PROFILE : (profileType === "freelancer") ?  DB_FREELANCER_PROFILE : false
  const userProfileId =freelancerId || companyId

  if (dbName && userProfileId)  {
    const docRef = doc(db, dbName, userProfileId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const companyData = docSnap?.data();
      // console.log("companyData", companyData);
      return companyData;
    } else {
      
      return false;
    }
  } 
  
};
