import { CountrySelection, MainInput } from "components/Form";
import { useDbAddNewEntry } from "hooks";
import Slider from "rc-slider";
import { useState } from "react";
import { Button, Container, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { DB_JOB_POST } from "shared/Keys";
import cx from "classnames";
import PrimaryLocation from "components/Form/PrimaryLocation";
import { WithContext as ReactTags } from "react-tag-input";
import { SKILLS } from "./skills";
import { useAuthValue } from "shared/AuthContext";
import moment from "moment";

export default function PostJob() {
  const { onDbSubmit } = useDbAddNewEntry();
  const { currentUser, userConfig } = useAuthValue();

  console.info(userConfig, "*****");

  const [inputs, setInputs] = useState({
    title: "",
    companyName: "",
    description: "",
    jobType: "",
    minJobMoney: 500,
    maxJobMoney: 1000,
    minExperience: 0,
    maxExperience: 5,
    state:'',
    country:'',
    postdate: parseInt(moment().unix()),
  });

  console.log("***** inputs ******", inputs);

  const [tags, setTags] = useState([{ id: "JavaScript", text: "JavaScript" }]);

  const { title, description, jobType, companyName, state,country } = inputs;

  const handleInput = (name, value) => {
    setInputs((previous) => ({ ...previous, [name]: value }));
  };

  const submitValue = { ...inputs, companyId: userConfig?.companyId };

  const onSubmit = () => {
    console.log("*** submitValue ***", submitValue);
    onDbSubmit({ dbKey: DB_JOB_POST, variables: submitValue, navigateTo: "/" });
  };

  function renderJobRequirement() {
    // Tags
    const suggestions = SKILLS.map((skill) => {
      return { id: skill, text: skill };
    });

    const KeyCodes = { comma: 188, enter: 13 };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleDelete = (i) => {
      setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
      setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
      const newTags = tags.slice();

      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);

      // re-render
      setTags(newTags);
    };

    const handleTagClick = (index) => {
      console.log("The tag at index " + index + " was clicked");
    };

    const experienceHandler = (value) => {
      const [minExperience, maxExperience] = value;
      setInputs((previous) => ({
        ...previous,
        minExperience: minExperience,
        maxExperience: maxExperience,
      }));
    };

    const jobTypeList = [
      { name: "Permanent", value: "Permanent" },
      { name: "Temporary", value: "Temporary" },
      { name: "Part-Time", value: "Part-Time" },
      { name: "Freelance", value: "Freelance" },
      // { name: "Full-Time", value: "Full-Time" },
      // { name: "Contract", value: "Contract" },
    ];

    return (
      <div>
        {/* <MainInput
          label="Job Type"
          type="select"
          name="jobType"
          placeholder="Select job type"
          value={jobType}
          onChange={handleInput}
          options={jobTypeList}
        /> */}
        <div className="main-input">
          <Label>Job Type</Label>
          <Row className="gy-2 gx-lg-2">
            {jobTypeList.map(({ name, value }) => (
              <Col xs={6} md={4} lg>
                <Button
                  color="selection"
                  className={cx("mb-0", { active: jobType === value })}
                  onClick={() => handleInput("jobType", value)}
                >
                  {name}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
        <Row>
          <Col sm={6}>
            <div className="main-input">
              <Label>Skills</Label>
              <ReactTags
                tags={tags}
                suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inputFieldPosition="top"
                autocomplete
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className="main-input">
              <Label>Experience</Label>
              <Slider
                min={0}
                max={10}
                range
                tipFormatter={(value) => `$ ${value}`}
                onChange={(value) => experienceHandler(value)}
                allowCross={false}
                defaultValue={[0, 5]}
                />
                <Row>
                  <Col sm={2}>
                    <Label>{inputs.minExperience}</Label>
                  </Col>
                  <Col sm={2}>
                    <Label>{inputs.maxExperience}</Label>
                  </Col>
                </Row>
            </div>
          </Col>
        </Row>

        <div style={{ paddingTop: 30 }}>
          <div className="job-type-action">
            <Row>
              <Col sm={6}>
                <MainInput
                  label="Salary Type"
                  type="select"
                  name="salarytype"
                  placeholder="Select Salary Type"
                  onChange={handleInput}
                  options={[
                    { name: "Fixed", value: "Fixed" },
                    { name: "Range", value: "Range" },
                  ]}
                />
              </Col>
              <Col sm={6}>
                <MainInput
                  label="Method To Pay"
                  type="select"
                  name="methodPay"
                  placeholder="Select Method To Pay"
                  onChange={handleInput}
                  options={[
                    { name: "Hourly", value: "Hourly" },
                    { name: "Monthly", value: "Monthly" },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  function renderSalaryEstimates() {
    const sliderHandler = (value) => {
      const [minJobMoney, maxJobMoney] = value;
      setInputs((previous) => ({
        ...previous,
        minJobMoney: minJobMoney,
        maxJobMoney: maxJobMoney,
      }));
    };
    return (
      <div className="main-input renderSalaryEstimates">
        <label className="form-label">Salary Estimates</label>
        <div className="d-flex justify-content-between mb-1">
          <p className="mb-0">$500.00</p>
          <p className="mb-0">$6800.00</p>
        </div>
        <Slider
          min={500}
          max={6800}
          range
          tipFormatter={(value) => `$ ${value}`}
          onChange={(value) => sliderHandler(value)}
          allowCross={false}
          defaultValue={[500, 1000]}
        />
         <Row>
          <Col sm={2}>
            <Label>{inputs.minJobMoney}</Label>
          </Col>
          <Col sm={2}>
            <Label>{inputs.maxJobMoney}</Label>
          </Col>
        </Row>
      </div>
    );
  }
  console.log("userConfig", { userConfig });
  // if (userConfig?.companyId) {
  if (userConfig?.profileType === "client") {
    return (
      <section className="py-5">
        <Container>
          <div className="post-job form-screen">
            <h1 className="title">
              Post job from anywhere,
              <br />
              anytime! 💼
            </h1>
            <Row>
              <Col sm={6}>
                <MainInput
                  label="Job Title"
                  name="title"
                  value={title}
                  placeholder="Enter job title"
                  onChange={handleInput}
                />
              </Col>
              <Col sm={6}>
                <MainInput
                  label="Company Name"
                  name="companyName"
                  value={companyName}
                  placeholder="Enter company name"
                  onChange={handleInput}
                />
              </Col>
            </Row>

            {renderJobRequirement()}

            <Row className="mb-4">
              <Col sm={6}>
                <CountrySelection onChange={handleInput} value={inputs.country} label="Select Country" />
              </Col>
              <Col sm={6}>
              <MainInput
                  label="State"
                  name="state"
                  value={state}
                  placeholder="Enter State"
                  onChange={handleInput}
                />
              </Col>
              <Col sm={6}>
                <MainInput
                  label="Category"
                  type="select"
                  name="jobCategory"
                  placeholder="Select job category"
                  onChange={handleInput}
                  options={[
                    { name: "Front-End", value: "Front-End" },
                    { name: "Back-End", value: "Back-End" },
                    { name: "Database", value: "Database" },
                    { name: "UI/UX", value: "UI/UX" },
                    { name: "GameDeveloper", value: "GameDeveloper" },
                    { name: "Marketing", value: "Marketing" },
                    { name: "Banker", value: "Banker" },
                  ]}
                />
              </Col>
              <Col sm={6}>{renderSalaryEstimates()}</Col>
            </Row>
            <MainInput
              type="textarea"
              label="Description"
              name="description"
              value={description}
              placeholder="Enter post description"
              onChange={handleInput}
            />

            {/* <pre>{JSON.stringify({ inputs }, null, 2)}</pre> */}
            <div className="px-5 mt-5 mx-auto" style={{ maxWidth: 373 }}>
              <Button color="primary" className="w-100" onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Container>
      </section>
    );
  } else {
    return <div>Create a profile first</div>;
  }
}
