import React, { useContext,useState,useEffect } from "react";
import { GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  applyActionCode,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, db } from "firebase-config";
import { DB_USER_CONFIG } from "shared/Keys";
import { doc, getDoc } from "firebase/firestore";


const AuthContext = React.createContext();

export function useAuthValue() {
  return useContext(AuthContext);
}

export function AuthProvider({ children}) {
  const [currentUser, setCurrentUser] = useState(false);
  const [userConfig, setUserConfig] = useState(false);
  const [timeActive, setTimeActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [storingJobIdSelected,setStoringJobIdSelected] = useState(null)
  const [authstateOnce,setAuthstateOnce] = useState(true)

  
  const signInWithGoogle  = async () => {
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider)
      authStateChanged()
      return res
    
  };

  const sendEmailVerificationMail =async() =>{
    console.log(auth.currentUser)
    const res =  sendEmailVerification(auth.currentUser)
    return res
  }

  const handleVerifyEmail =async(auth, actionCode) => {

    const resp = applyActionCode(auth, actionCode)
    return resp
  }
  

  const  verifyEmailAddress = async(mode,actionCode)=>{
   
    switch (mode) {
      // case 'resetPassword':
      //   // Display reset password handler and UI.
      //   handleResetPassword(auth, actionCode, continueUrl, lang);
      //   break;
      // case 'recoverEmail':
      //   // Display email recovery handler and UI.
      //   handleRecoverEmail(auth, actionCode, lang);
      //   break;
      case 'verifyEmail':
         const rep =  await handleVerifyEmail(auth, actionCode);
          return rep
        break;
      default:
        // Error: invalid mode.
    }
  }




  const signUp = async(email,password) => {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      return res;
  }

  const login = async (email,password) =>{
    const res = await signInWithEmailAndPassword(auth, email, password)
    return res;
  }

  const logout=async()=>{
    const res = await signOut(auth)
    setUserConfig('')
    setCurrentUser('')
    return res
  }

  const authStateChanged = ()=>{
    // setUserConfig('')
    // setCurrentUser('')
    const unsubscribe=onAuthStateChanged(auth,  async(user) => {
      console.log('current userDetails',user)
      if (user?.uid){
        const docRef = doc(db, DB_USER_CONFIG, user?.uid);
        const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setCurrentUser(user);
            setUserConfig(docSnap.data());
            console.log("authContent:",  docSnap.data());
          } else {
            setCurrentUser(user);
            setUserConfig(false)
            console.log("New User Created OR authContent No such documents!");
          }
        }else{
          console.log("No user");
        }
        setLoading(false)
        console.log('Running .....authState')
    });

    return unsubscribe

  }



  useEffect(() => {
    authStateChanged()

  }, []);

  const value = {
    signInWithGoogle,
    signUp,
    currentUser,
    userConfig,
    login,
    logout,
    authStateChanged,
    setStoringJobIdSelected,
    storingJobIdSelected,
    sendEmailVerificationMail,
    verifyEmailAddress,
    // resetPassword,
    // updateEmail,
    // updatePassword,
    timeActive,
    setTimeActive,
  }



  return (
      <AuthContext.Provider value={value}>
        {!loading && children}
        {/* { children} */}
      </AuthContext.Provider>
    )

}


