export const userClientDetails = {
  companyName: "",
  companyWebsite: "",
  aboutCompany: "",
  companyActivity: "",
  yourName: "",
  email: "",
  yourRole: "",
  address: "",
  country: "",
  state: "",
  city: "",
  zipPostalCode: "",
  logo: "",
};

export const userFreelancerDetails = {
  logo: "",
  languages: "",
  yourName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  gender: "",
  nationality: "",
  salary: [500, 100],
  description: "",
  jobCategory: "",
  education: "",
  lastRole: "",
  totalYearOfExperience: "",
  experienceDetail: "",
  skills: "",
  address: "",
  country: "",
  state: "",
  city: "",
  zipPostalCode: "",
};

export const ProfileTypeInput = (profileType, profileInputDetails) => {
  if (profileType === "client" && profileInputDetails) {
    const clientProfile = [
      //  {
      //   label: "Logo",
      //   type: 'file',
      //   name: "logo",
      //   value: profileInputDetails.logo,
      //   placeholder: "Enter yout role in company",
      //   // onChange: handleInput,
      //   selectOptions: null,
      // },

      {
        label: "Name of the company",
        // type: null,
        name: "companyName",
        value: profileInputDetails?.companyName,
        placeholder: "Enter your company name",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Company website",
        // type: null,
        name: "companyWebsite",
        value: profileInputDetails?.companyWebsite,
        placeholder: "Enter your company website",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "About Company",
        type: "textarea",
        name: "aboutCompany",
        value: profileInputDetails?.aboutCompany,
        placeholder: "Write about your company",
        maxLength: 20000,
        // onChange: handleInput,
        selectOptions: null,
        sm: 12,
      },
      {
        label: "Activity of the company",
        type: "select",
        name: "companyActivity",
        value: profileInputDetails?.companyActivity,
        placeholder: "Select company activity",
        // onChange: handleInput,
        selectOptions: [
          { name: "Engineering", value: "Engineering" },
          { name: "Marketing", value: "Marketing" },
          { name: "Agency", value: "Agency" },
          { name: "Logistic", value: "Logistic" },
          { name: "Finance", value: "Finance" },
        ],
      },
      {
        label: "Your Name",
        // type: null,
        name: "yourName",
        value: profileInputDetails?.yourName,
        placeholder: "Enter yor name",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Your email with company domain ",
        // type: null,
        name: "email",
        value: profileInputDetails?.email,
        placeholder: "Example email@email.com",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Your role in company",
        // type: null,
        name: "yourRole",
        value: profileInputDetails?.yourRole,
        placeholder: "Enter yout role in company",
        // onChange: handleInput,
        selectOptions: null,
      },
    ];
    return clientProfile;
    // ------------------------------- End Client-------------------------------------------
  } else if (profileType === "freelancer" && profileInputDetails) {
    const candidateProfile = [
      {
        label: "Your name",
        // type: null,
        name: "yourName",
        value: profileInputDetails?.yourName,
        placeholder: "Enter your name",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Your email",
        // type: null,
        name: "email",
        value: profileInputDetails?.email,
        placeholder: "Example email@email.com",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Your phone number",
        // type: null,
        name: "phoneNumber",
        value: profileInputDetails?.phoneNumber,
        placeholder: "Enter your phone number",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Date of birth",
        // type: null,
        name: "dob",
        value: profileInputDetails?.dob,
        placeholder: "Enter date of birth ",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Select Gender",
        type: "select",
        name: "gender",
        value: profileInputDetails?.gender,
        placeholder: "Select gender",
        // onChange: handleInput,
        selectOptions: [
          { name: "Male", value: "Male" },
          { name: "Female", value: "Female" },
        ],
      },
      {
        label: "Your Nationality",
        // type: null,
        name: "nationality",
        value: profileInputDetails?.nationality,
        placeholder: "Enter Nationality",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Select job category",
        type: "select",
        name: "jobCategory",
        value: profileInputDetails?.jobCategory,
        placeholder: "Select job category",
        // onChange: handleInput,
        selectOptions: [
          { name: "Engineering", value: "Engineering" },
          { name: "Marketing", value: "Marketing" },
          { name: "Agency", value: "Agency" },
          { name: "Logistic", value: "Logistic" },
          { name: "Finance", value: "Finance" },
        ],
      },

      {
        label: "About you",
        type: "textarea",
        name: "description",
        value: profileInputDetails?.description,
        placeholder: "Description about you",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Salary Estimated",
        // type: null,
        name: "salary",
        value: `${profileInputDetails?.salary[0]} - ${profileInputDetails?.salary[1]}`,
        placeholder: "Description about you",
        // onChange: handleInput,
        selectOptions: null,
      },

      {
        label: "Education Details",
        // type: null,
        name: "education",
        value: profileInputDetails?.education,
        placeholder: "Enter your Education Details ",
        // onChange: handleInput,
        selectOptions: null,
      },

      {
        label: "Last Role",
        // type: null,
        name: "lastRole",
        value: profileInputDetails?.lastRole,
        placeholder: "Enter your last role in company",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Experince",
        // type: null,
        name: "totalYearOfExperience",
        value: profileInputDetails?.totalYearOfExperience,
        placeholder: "Total working experience",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Experince Details",
        type: "textarea",
        maxLength: 20000,
        name: "experienceDetail",
        value: profileInputDetails?.experienceDetail,
        placeholder: "Write about your work experience",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Add Skills",
        // type: null,
        name: "skills",
        value: profileInputDetails?.skills,
        placeholder: "Add Skills",
        // onChange: handleInput,
        selectOptions: null,
      },
      {
        label: "Add language",
        // type: null,
        name: "languages",
        value: profileInputDetails?.languages,
        placeholder: "Add languages",
        // onChange: handleInput,
        selectOptions: null,
      },
    ];
    return candidateProfile;
  }
};
