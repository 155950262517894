import memberIcon1 from "assets/images/about/crew/member-icon-1.png";
import memberIcon2 from "assets/images/about/crew/member-icon-2.png";
import memberIcon3 from "assets/images/about/crew/member-icon-3.png";
import memberIcon4 from "assets/images/about/crew/member-icon-4.png";
import memberIcon5 from "assets/images/about/crew/member-icon-5.png";
import memberIcon6 from "assets/images/about/crew/member-icon-6.png";
import memberIcon7 from "assets/images/about/crew/member-icon-7.png";
import memberIcon8 from "assets/images/about/crew/member-icon-8.png";
import memberIcon9 from "assets/images/about/crew/member-icon-9.png";
import memberIcon10 from "assets/images/about/crew/member-icon-10.png";
import memberIcon11 from "assets/images/about/crew/member-icon-11.png";
import memberIcon12 from "assets/images/about/crew/member-icon-12.png";

export const crewData = [
  {
    title: "US Chapters",
    members: [
      {
        icon: memberIcon1,
        name: "Anya Jain",
        designation: "Founder",
      },
      {
        icon: memberIcon2,
        name: "Pavlo Kovalev",
        designation: " ",
      },
      {
        icon: memberIcon7,
        name: "Rachel Miller",
        designation: " ",
      },
    ],
  },
  {
    title: "Ukraine Chapters",
    members: [
      {
        icon: memberIcon3,
        name: "Vlada Belous",
        designation: "Chapter Head - Kyiv, Ukraine",
      },
      {
        icon: memberIcon6,
        name: "Lidiya Vlasenko",
        designation: "Chapter Head - Odessa, Ukraine",
      },
    ],
  },
  {
    title: "International Chapters",
    members: [
      {
        icon: memberIcon4,
        name: "Elizabeth Hall",
        designation: "Chapter Head - Munich, Germany",
      },
    ],
  },
];
