import { useState, useEffect } from "react";

// import { getAuth, sendEmailVerification } from "firebase/auth";

import { useAuthValue } from "shared/AuthContext";
import { auth } from "firebase-config";
import { Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function VerifyEmail() {
  const { currentUser, verifyEmailAddress, authStateChanged } = useAuthValue();
  const location = useLocation();
  const urlParameter = new URLSearchParams(location.search);
  const mode = urlParameter.get("mode");
  const actionCode = urlParameter.get("oobCode");
  const [message, setMessage] = useState("");
  const [running, setrunning] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log('before currentUser ***',currentUser)
    if (running) {
      verifyEmailAddress(mode, actionCode)
        .then((rep) => {
          currentUser.reload();
          setrunning(false);
          console.log("after currentUser?.emailVerified ***", currentUser);
          navigate("/");
          if (currentUser?.emailVerified) {
            setMessage("Your Email has been verified");
            navigate("/");
          }
        })
        .catch((error) => {
          navigate("/");
          // setMessage(error.message);
          console.log(error);
        });
    }
    return () => {};
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //
  //       .catch((err) => {
  //         alert(err.message);currentUser
  //       ?.reload()
  //       .then(() => {
  //         console.log('currentUser?.emailVerified',currentUser?.emailVerified)
  //         if (currentUser?.emailVerified) {
  //           clearInterval(interval);
  //           // navigate("/");
  //           navigate("/profile-type");
  //         }
  //       })
  //       });
  //   }, 1000);
  // }, [navigate, currentUser]);

  // useEffect(() => {
  //   let interval = null;
  //   if (timeActive && time !== 0) {
  //     interval = setInterval(() => {
  //       setTime((time) => time - 1);
  //     }, 1000);
  //   } else if (time === 0) {
  //     setTimeActive(false);
  //     setTime(60);
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [timeActive, time, setTimeActive]);

  // const resendEmailVerification = () => {
  //   sendEmailVerification(auth.currentUser)
  //     .then(() => {
  //       // setTimeActive(true);
  //       // navigate("/profile-type");
  //     })
  //     .catch((err) => {
  //       alert(err.message);
  //     });
  // };

  return (
    <div className="center">
      <div className="verifyEmail">{message}</div>
    </div>
  );
}
