import { Col, Container, Row, Button } from "reactstrap";
import banner from "assets/images/banner/about-info.png";

export default function Info() {
  return (
    <section className="bg-light-gray-200 py-5">
      <Container>
        <Row>
          <Col md={6}>
            <div className="me-md-5">
              <img src={banner} className="img-fluid" alt="" />
            </div>
          </Col>
          <Col md={6}>
            {" "}
            {/* <p className="font-weight-600 text-primary mb-3">Team</p> */}
            <h4>Who We Are</h4>
            <div style={{ opacity: 0.7 }}>
              <p>
              We are a student-led non-profit organization committed to helping Ukrainian refugees
               impacted by this  ongoing humanitarian crisis. Our goal is to create a global network 
               connecting employers and job seekers and to disseminate the information by partnering with
                organizations and volunteers in various parts of the world. We don’t charge for our services.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
