import { initializeApp } from "firebase/app";
import { getAuth,} from "firebase/auth";
import { getFirestore }from "firebase/firestore";
// import { DB_USER_CONFIG } from "shared/Keys";


const firebaseConfig = {
  apiKey: "AIzaSyDMRBfqpyQxtEl8RdIK424BRy7CAwXzUis",
  authDomain: "jobsearch-c9670.firebaseapp.com",
  projectId: "jobsearch-c9670",
  storageBucket: "jobsearch-c9670.appspot.com",
  messagingSenderId: "320177879489",
  appId: "1:320177879489:web:18163120a537129991d740"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


export { auth, db }