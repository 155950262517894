import { useMemo, useState } from "react";
import ReactSelect from "react-select";
import countryList from "react-select-country-list";

export default function CountrySelection(props) {
  const { label = "" } = props;
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    props?.onChange('country',value.label)
    setValue(value);
  };
  return (
    <div className="main-input">
      {label && <label className="form-label">{label}</label>}
      <ReactSelect
        classNamePrefix="theme-react-select"
        options={options}
        value={value}
        onChange={changeHandler}
      />
    </div>
  );
}
