import { Col, Container, Row, Button } from "reactstrap";
import banner from "assets/images/banner/about-banner.png";

export default function Banner() {
  return (
    <section className="py-5">
      <Container>
        {/* <h1 className="font-weight-300 mb-3">Mission💼</h1> */}
        <h2>Mission💼</h2>
        <Row>
          <Col md={6}>
            <div className="mb-5" style={{ opacity: 0.7 }}>
              <p>
                Over 7 million Ukrainian citizens have crossed into Europe and over 8 million have
                been displaced internally within Ukraine. We stand in solidarity with Ukraine and 
                are committed to supporting the displaced families by helping them find in-person 
                and virtual jobs, enabling them to rebuild their lives.
              </p>
            </div>
            <a
              className="btn btn-primary full-rounded"
              style={{ width: 260 }}
              href="mailto:ukraineemployed@gmail.com"
              target="_blank"
            >
              Get in touch
            </a>
          </Col>
          <Col md={6}>
            <img src={banner} className="img-fluid" alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
