import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/logo/logo.png";
import { useState } from "react";
import { useAuthValue } from "shared/AuthContext";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Button, Alert,Input,Spinner } from "reactstrap";
import LoginSignUpLayout from "components/LoginSignUpLayout";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const { setTimeActive ,signUp } = useAuthValue();


  const validatePassword = () => {
      if (password !== confirmPassword && password !== "" && confirmPassword !== "") {
        setError("Passwords does not match");
        return  false;
    }
    return true;
  };

  const register = async(e) => {
    e.preventDefault();
    setError("");
    if (validatePassword()) {
      try {
        setLoading(true);
        await signUp(email,password)
        .then((data) => {
          if(data){
            setTimeout(() => {

              console.log('Navigate to profile')
              navigate('/profile')

              
            },1000)
            console.log('dddddaaaaaaaaattta',data)

          }

        })
      }catch(e){
        setError(e.message)
        console.log('erorr',e.message)
      }
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    }
    setLoading(false);
  };

  return (
    <LoginSignUpLayout>
      <div className="center">
        <div className="auth">
          <h3 className="mb-4">Register</h3>
          <form onSubmit={register} name="registration_form">
          {error && <Alert variant={'danger'}> {error} </Alert>}
            <Input
              type="email"
              value={email}
              placeholder="Enter your email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              type="password"
              value={password}
              required
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Input
              type="password"
              value={confirmPassword}
              required
              placeholder="Confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <p className="text mt-5 pt-lg-4">
              By creating an account, you agreeing to our Privacy Policy, and Suvi Infotech Policy.
            </p>
            
            <Button disabled={loading} type="submit" color="primary" className="w-100 mb-3">
              Sign Up
            </Button>
            {loading && <Spinner color="primary">Loading...</Spinner>}

          </form>
          <span>
            Already have an account?
            <div>
            <Link to="/login">Login</Link>
            </div>
          </span>
        </div>
      </div>
    </LoginSignUpLayout>
  );
}
