import { db } from "firebase-config";
import { serverTimestamp, setDoc, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Row, Col, Button, Container } from "reactstrap";
import { useAuthValue } from "shared/AuthContext";
import { DB_USER_CONFIG } from "shared/Keys";
import freelancerIcon from "assets/images/icons/freelancer.svg";
import clientIcon from "assets/images/icons/client.svg";
import checkIcon from "assets/images/icons/check.svg";
import { GrayBackgroundTheme } from "shared/Theme";
import { useNavigate } from "react-router-dom";

export default function ProfileType() {
  const navigate = useNavigate();
  const { currentUser, userConfig, authStateChanged} = useAuthValue();
  const [selectProfileType, setSelectProfileType] = useState(userConfig?.profileType || "");

  console.log("*** currentUser ***", currentUser);

  const typeData = [
    { icon: freelancerIcon, name: "I’m freelancer, looking for work", type: "freelancer" },
    { icon: clientIcon, name: "I’m client, hiring for a project", type: "client" },
  ];


  const CreateUserConfig = (p) => {
    setDoc(doc(db, DB_USER_CONFIG, currentUser?.uid), {
      userId: currentUser?.uid,
      profileType:selectProfileType,
      timestamp: serverTimestamp(),
    })
      .then((e) => {
        authStateChanged()
        navigate("/profile");
      })
      .catch((error) => {
        alert(`Unsuccessful returned error: ${error.message}`);
      });
  };

  return (
    <section className="py-5 profile-type">
      <GrayBackgroundTheme />
      <Container>
        <div className="mx-auto" style={{ maxWidth: 780 }}>
          <h1 className="text-center mb-5">Find a job or post job</h1>
          {/* <pre>{JSON.stringify({ userConfig, profileType }, null, 2)}</pre> */}
          <Row>
            {typeData.map(({ icon, name, type }, index) => (
              <Col md={6} key={index} className="mb-4">
                <div className="profile-type-card" onClick={() => setSelectProfileType(type)}>
                  <div className="mb-4">
                    <img src={icon} alt={name} />
                  </div>
                  {(type === selectProfileType || (!selectProfileType && type === userConfig?.profileType)) && (
                    <img className="active-icon" src={checkIcon} alt="active" />
                  )}
                  <p className="name">{name}</p>
                </div>
              </Col>
            ))}
          </Row>
          <div className="mx-auto mt-5" style={{ maxWidth: 432 }}>
            <Button color="primary" className="submit w-100" onClick={CreateUserConfig}>
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}
