import MenuList from "./MenuList";
import RangeFilter from "./RangeFilter";
import moment from 'moment';
import { MainInput } from "components/Form";
import { useState,useEffect } from "react"
import { doc, getDoc } from "firebase/firestore";
import { db } from "firebase-config";


export default function FilterPost({onClick}) {
  const [countrylist,setCountrylist] =useState([])
  const country = async() =>{
    const docRef = doc(db, "countries", "countries");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setCountrylist(docSnap.data().countries)
      // console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

  }

                    
  // console.log('countrylist',countrylist)

  useEffect(() => {
    country()
  }, [])
  


  
  const dateSelected =(option)=>{

    const dateCondition = (option === 'latest')? 7 : (option === 'week') ? 20 : 30
    const endDate = moment().subtract(dateCondition, 'days').format('YYYY-MM-DD');
    const endDateUnix = moment(endDate).unix()
    // console.log('dateCondition',dateCondition)
    // console.log('endDate',endDate)
    // console.log('endDateUnix',endDateUnix)
    onClick(endDateUnix,'date')
  }
  

  const filterMenuData = [
    { name: "Date Posted", 
      menus: [{ name: "Newest ", onClick: (option) => dateSelected(option),option:'latest'}, 
            { name: "A week ago", onClick:(option)=> dateSelected(option),option:'week'},
            { name: "30 Days", onClick:(option,)=> dateSelected(option),option:'30days'}
      ]},

    { name: "Job Type", 
      type:'jobType',
      menus: [ 
        { name: "Permanent", option:'Permanent' ,onClick:(option)=>onClick(option,'jobType')},
        { name: "Temporary", option:'Temporary' ,onClick:(option)=>onClick(option,'jobType')},
        { name: "Part-Time", option:'Part-Time' ,onClick:(option)=>onClick(option,'jobType') },
        { name: "Freelance", option:'Freelance' ,onClick:(option)=>onClick(option,'jobType')  },
      // { name: "Full-Time", option:'Full-Time' ,onClick:(option)=>onClick(option,'jobType') },
      // { name: "Contract",  option:'Contract' , onClick:(option)=>onClick(option,'jobType')},
    ] },
    
    

    
  ];
  
  // const filterMenuData2 = [
  //   { name: "Experience Level", menus: [{ name: "menu1 " }, { name: "menu 2" }] },
  // ];
  
  function renderLocation() {

    const handleInput = (name,value)=>{
      onClick(value,'country')
    }

    return (
      <div>
        {/* <p className="sub-filter-title">Salary Estimates</p> */}
        <MainInput
                  label="Country"
                  type="select"
                  name="country"
                  // value = {profileInputDetails?.country}
                  placeholder="Select Country"
                  onChange={handleInput}
                  options={
                    countrylist
                  }
                  style={{ borderRadius: 10 }}
                />
      </div>
    );
  }
  return (
    <div className="FilterPost">
      <div className="head">Filter By</div>
      <div className="body-content">
        {filterMenuData.map((data, index) => (
          <div key={"filterMenuData" + index}>
            <MenuList {...data}   />
          </div>
        ))}
        {renderLocation()}
        {/* {filterMenuData2.map((data, index) => (
          <div key={"filterMenuData2" + index}>
            <MenuList {...data} />
          </div>
        ))} */}
      </div>
    </div>
  );
}
