import { CountrySelection, MainInput } from "components/Form";
import PrimaryLocation from "components/Form/PrimaryLocation";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export default function AddEducationHistoryModal(props) {
  const { active = true, close = () => {} } = props;
  return (
    <Modal isOpen={active} toggle={close} centered size="lg">
      <ModalHeader toggle={close}>Add Education History</ModalHeader>
      <ModalBody>
        <Row className="gx-lg">
          <Col xs="6">
            <MainInput label="Degree" />
          </Col>
          <Col xs="6">
            <MainInput label="Field of study" placeholder="Ex: Business" />
          </Col>

          <Col xs="6">
            <MainInput
              label="Start Year"
              type="select"
              placeholder="Select Year"
              options={[
                { name: "option 1", value: "option-1" },
                { name: "option 1", value: "option-1" },
              ]}
            />
          </Col>
          <Col xs="6">
            <MainInput
              label="End Year"
              type="select"
              placeholder="Select Year"
              options={[
                { name: "option 1", value: "option-1" },
                { name: "option 1", value: "option-1" },
              ]}
            />
          </Col>
        </Row>
        <center>
          <Button color="primary" className="submit mt-4 px-5 rounded-5">
            Save
          </Button>
        </center>
      </ModalBody>
    </Modal>
  );
}
