import { CardMainPost } from "components/Cards";
import { FilterPost } from "components/Filters";
import { db } from "firebase-config";
import {
  collection,
  orderBy,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { mockCategoriesLitData, mockPostData } from "shared/MockData";
import { DB_JOB_POST, DB_FREELANCER_PROFILE } from "shared/Keys";
import { useAuthValue } from "shared/AuthContext";
import { Spinner, Container } from "reactstrap";
import { SearchFilterPost } from "components/Filters";
import moment from "moment";
export default function MainPostDataLayout() {
  const [loading, setLoading] = useState(true);
  const { currentUser, userConfig } = useAuthValue();
  const [userDetails, setUserDetails] = useState([]); // master
  const [filterData, setfilterData] = useState([]);

  // console.log('profileType',profileType)

  const getUserData = async () => {
    console.log("***** useEffect userConfig *****", userConfig);
    if (
      userConfig?.profileType === "freelancer" ||
      !currentUser ||
      !userConfig
    ) {
      console.log("count , freelancer");
      const q = query(collection(db, DB_JOB_POST),orderBy("postdate", "desc"));
      const querySnapshot = await getDocs(q);
      let jobLists = [];
      querySnapshot.forEach((doc) =>
        jobLists.push({ id: doc.id, ...doc.data() })
      );
      const expjob = query(collection(db, "expjob"),orderBy("postdate", "desc"));
      const expJobQuery = await getDocs(expjob);
      expJobQuery.forEach((doc) =>
        jobLists.push({ id: doc.id, ...doc.data() })
      );
      setUserDetails(jobLists);
      setLoading(false);
    }
    if (userConfig?.profileType === "client") {
      console.log("count ,client");
      const q = query(collection(db, DB_FREELANCER_PROFILE));
      const querySnapshot = await getDocs(q);
      let candidateLists = [];
      querySnapshot.forEach((doc) =>
        candidateLists.push({ id: doc.id, ...doc.data() })
      );
      setUserDetails(candidateLists);
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserDetails([]);
    getUserData();
    return () => {};
  }, [userConfig]);

  // const filterUserCumJob = (text) => {

  //   if(text){
  //     const newData=masterData.filter((item)=>{
  //         console.log(item.name)
  //         const itemData=item.name ? item.name.toLowerCase():''.toLowerCase();
  //         const textData=text.toLowerCase();
  //         console.log(textData)
  //         return itemData.indexOf(textData)>-1;
  //     });
  //     if(newData.length>0 && text.length>0){
  //         setfilterData(newData)
  //     }
  // }
  // else{
  //     setfilterData(masterData)
  // }

  // }

  function renderCategoriesLitData() {
    return (
      <div className="text-center post-category-list">
        {mockCategoriesLitData?.map(({ name }, index) => (
          <Button
            key={"mockCategoriesLitData" + index}
            color="gray"
            outline
            className="full-rounded ms-3 mb-3"
          >
            {name}
          </Button>
        ))}
      </div>
    );
  }
  function renderPostData() {
    // console.log('**** renderPostData todos ****',todos)
    console.log("renderPostData userDetails", userDetails);

    return (
      <div>
        <div
          style={{
            justifyContent: "center",
            flex: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          {loading && <Spinner color="primary">Loading...</Spinner>}
        </div>
        <div>
          {!loading &&
            userDetails?.map((data, index) => (
              <div key={"postData" + index}>
               {data.jobshow && <CardMainPost {...data} />}
              </div>
            ))}
        </div>
      </div>
    );
  }
  const filter = async (value, type) => {
    setLoading(true);
    let jobListsfilter = [];
    if (type === "date") {
      if (
        !userConfig?.profileType ||
        userConfig?.profileType === "freelancer"
      ) {
        // console.log("I am filterDate....", moment.unix(value).format());
        // console.log("value....", value);
        const q = query(
          collection(db, "expjob"),
          where("postdate", ">=", value)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) =>
          jobListsfilter.push({ id: doc.id, ...doc.data() })
        );
        const q2 = query(
          collection(db, DB_JOB_POST),
          where("postdate", ">=", value)
        );
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) =>
          jobListsfilter.push({ id: doc.id, ...doc.data() })
        );
        setUserDetails(jobListsfilter);
      }
    } else if (type === "jobType") {
      if (
        !userConfig?.profileType ||
        userConfig?.profileType === "freelancer"
      ) {
        console.log("job....", value);
        const q = query(
          collection(db, "expjob"),
          where("jobType", "==", value)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) =>
          jobListsfilter.push({ id: doc.id, ...doc.data() })
        );
        const q2 = query(
          collection(db, DB_JOB_POST),
          where("jobType", "==", value)
        );
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) =>
          jobListsfilter.push({ id: doc.id, ...doc.data() })
        );
        setUserDetails(jobListsfilter);
      }
    } else if (type === "country") {
      if (
        !userConfig?.profileType ||
        userConfig?.profileType === "freelancer"
      ) {
        if (value === 'Select Country'){
          getUserData()
        }else{

          
          const q = query(
            collection(db, "expjob"),
            where("country", "==", value)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) =>
            jobListsfilter.push({ id: doc.id, ...doc.data() })
            );
            const q2 = query(
              collection(db, DB_JOB_POST),
              where("country", "==", value)
              );
              const querySnapshot2 = await getDocs(q2);
              querySnapshot2.forEach((doc) =>
              jobListsfilter.push({ id: doc.id, ...doc.data() })
              );
              setUserDetails(jobListsfilter);
            }
      }
    }

    setLoading(false);
  };

  return (
    <div>
      {/* <section style={{paddingTop:'10px',paddingBottom:'40px',}}>
          <Container>
            <SearchFilterPost  />
          </Container>
        </section> */}

      {/* {renderCategoriesLitData()} */}
      <Row>
        <Col lg={4} xl={3}>
          <FilterPost
            onClick={(value, type) => {
              console.log('value filter',value  )
              filter(value, type);
            }}
          />
        </Col>
        <Col lg={8} xl={9}>
          {renderPostData()}
        </Col>
      </Row>
    </div>
  );
}
