import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import {applyJobApi} from 'dataserivce/appliedJob/appliedjob-api'
import {Link} from 'react-router-dom';
import { useAuthValue } from "shared/AuthContext";
export const RightModal = ({ isOpen, toggle, args, userData, profileType, freelancerId, companyId,jobCompanyId }) => {

  const { yourName, yourRole, description, skills, jobCategory, experience,companyName,title,id:jobId ,userId,} = userData;
  const {currentUser,userConfig,setStoringJobIdSelected} = useAuthValue()



  // console.log('userData',userData)
  // console.log('userConfig',userConfig)
  
  const applyHandler=()=>{
    setStoringJobIdSelected('')
    if (profileType==='client'){
      // console.log('I am a Client.....',jobId,companyId) // jobId = profile-ID-freelancer
      applyJobApi(jobId,companyId,profileType)      
      toggle()
     }
    if (profileType==='freelancer'){
      // console.log('I am a freelancer......',id)
      if (userData.mail){
        
        applyJobApi(jobId,freelancerId,profileType,jobCompanyId) // jobId = jobId, jobCompanyId = companyId
        
      }else{

        applyJobApi(jobId,freelancerId,profileType,jobCompanyId) // jobId = jobId, jobCompanyId = companyId
      }
      
      toggle()

    }
  }


  return (
    <Modal isOpen={isOpen} toggle={toggle} {...args}>
      <ModalBody>
      <div className="center container p-5 my-5 border">
        <div>
            <Col md={10}>
              <div>
                <h1>
                  <strong>{ yourName || companyName }</strong>
                </h1>
              </div>
              <div>
                <h3>{yourRole || title}</h3>
              </div>
              <div>
                <Row>
                  <Col md={0}>
                    <h4>{userData?.jobType} </h4>
                  </Col>
                  <Col md={0}>
                    <h4>{userData?.jobCategory}</h4>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={0}>
              { (currentUser && (userConfig?.freelancerId || userConfig?.clientId)) ?  <Button color="primary" onClick={applyHandler}>
                  {(userData.mail)?<a href={userData.mail}>Apply</a>:
                  <div>Apply</div> 
                  }
                 </Button>
                : (currentUser && (!userConfig?.freelancerId || !userConfig?.clientId)) ?<Button onClick={()=>setStoringJobIdSelected(jobId)} ><Link to='/profile'> Create a Profile to apply </Link></Button> 
                :<Button onClick={()=>setStoringJobIdSelected(jobId)}><Link to='/login' > Login to Apply</Link></Button> 
              }
              <Button style={{ marginLeft:'1em',borderWidth:'0.05em',borderColor:'black'}} color="white" onClick={toggle}> 
                Cancel
              </Button>
            </Col>
        </div>

        <div style={{ paddingTop:'2em'}}>
          <h3>About</h3>
        </div>
        <div style={{whiteSpace:'pre-wrap'}}>{description}</div>

        {
          userData.maxExperience && 
          <div>  
            <div style={{ paddingTop:'1em'}}>
              <h3>Required Experience</h3>
            </div>
            <div>{`${userData?.minExperience} - ${userData.maxExperience}` || experience}</div>
          </div>

        }
        { userData?.maxJobMoney &&
          <div>
            <div style={{ paddingTop:'1em'}}>
              <h3>Salary</h3>
            </div>
            <div>{`${userData?.minJobMoney} - ${userData?.maxJobMoney}`}</div>
        </div>
        }
        { skills &&
          <div>
            <div style={{ paddingTop:'1em'}}>
              <h3>Skills</h3>
            </div>
            <div>{skills}</div>
          </div>
        }
        </div>
      </ModalBody>

    </Modal>
  );
};
