import { db } from "firebase-config";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthValue } from "shared/AuthContext";

export default function useDbAddNewEntry(props) {
  const { currentUser } = useAuthValue();
  const navigate = useNavigate();

  const onDbSubmit = ({ dbKey, variables = {}, navigateTo = "/" }) => {
    addDoc(collection(db, dbKey), {
      userId: currentUser?.uid,
      
      timestamp: serverTimestamp(),

      ...variables,
    });
    navigate(navigateTo);
  };
  return { onDbSubmit };
}
