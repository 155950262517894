import { Card, Col, Container, Row } from "reactstrap";
import { crewData } from "./config";

function Member(props) {
  const { name, designation, icon } = props;
  return (
    <div className="member">
      <img src={icon} alt={name} />
      <p className="name">{name}</p>
      <p className="designation">{designation}</p>
    </div>
  );
}

function MembersCard(props) {
  const { title, members = [] } = props;
  return (
    <div className="crew-card">
      <h3>{title}</h3>
      <div className="content">
        {members.map((data, index) => (
          <Member key={"members" + index} {...data} />
        ))}
      </div>
    </div>
  );
}

export default function Crew() {
  return (
    <section className="py-5">
      <Container>
        <Row className="gy-3 justify-content-center">
          {crewData.map((data, index) => (
            <Col md={6} xl={4}>
              <MembersCard key={"crewData" + index} {...data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
