import { Container } from "reactstrap";
import mailIcon from "assets/images/icons/mail-icon.svg";

export default function GetInTouch() {
  return (
    <section className="py-5 ">
      <Container>
        <div className="text-center">
          <img src={mailIcon} alt="mail-icon" />
          <h4 className="font-weight-600">Get In Touch</h4>
          <p className="mb-5">
            To post jobs or to search through our listings, please create an account. 
            For inquiries or to report issues, please contact us.
          </p>

          <a
            className="btn btn-primary full-rounded"
            style={{ width: 260 }}
            href="mailto:ukraineemployed@gmail.com"
            target="_blank"
          >
            Get in touch
          </a>
        </div>
      </Container>
    </section>
  );
}
