import signInBanner from "assets/images/banner/sign-in-banner.png";

import bannerBackground from "assets/images/banner/signup-background.png";
import { Link } from "react-router-dom";
import logo from "assets/images/logo/logo-white.png";
import SocialSignUpForm from "./SocialSignUpForm";

export default function LoginSignUpLayout(props) {
  const { children } = props;
  return (
    <div className="sign-up-layout">
      <Link className="logo" to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div className="banner-content" style={{ backgroundImage: `url(${bannerBackground})` }}>
        <img src={signInBanner} className="img-fluid" />
      </div>
      <div className="form-content">
        <SocialSignUpForm>{children}</SocialSignUpForm>

        <div className="bottom-link d-flex align-items-center justify-content-between">
          <Link to="/sign-in">Privacy Policy</Link>
          <Link to="/sign-in">Copyright 2022</Link>
        </div>
      </div>
    </div>
  );
}
