export const  SKILLS = [
    'React Native',
    'Java',
    'C++',
    'C',
    'Node.js',
    'Python',
    'Django',
    'Php',
    'Laravel',
    'Next.js',
    'JavaScript',
    'MongoDB',
    'Express',
    'BUN',
    'Selvelte'
  ];