import { useState } from "react";
import { Button, Collapse } from "reactstrap";

function MenuItem(props) {
  const { name ,onClick,option } = props;


  // const dateSelected =()=>{

  //   const dateCondition = (option === 'latest')? 8 : (option === 'week') ? 20 : 30
  //   const endDate = moment().subtract(dateCondition, 'days').format('YYYY-MM-DD');
  //   const endDateUnix = moment(endDate).unix()
  //   // console.log('dateCondition',dateCondition)
  //   // console.log('endDate',endDate)
  //   // console.log('endDateUnix',endDateUnix)
  //   onClick(endDateUnix)
  // }
    
  return (
    <>
      <Button onClick={()=>onClick(option)} className="px-0 sub-menu-btn w-100 text-start">{name}</Button>
    </>
  );
}

export default function MenuList(props) {
  const { name, menus } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        className="w-100 px-0 d-flex align-items-center justify-content-between"
        onClick={() => setIsOpen((pre) => !pre)}
      >
        {name}
        <i className="fa-solid fa-angle-down" style={isOpen ? { transform: "scaleY(-1)" } : {}} />
      </Button>
      <Collapse isOpen={isOpen}>
        <div className="px-3 ">
          {menus.map((data, index) => (
            <div key={"menus" + index}>
              <MenuItem {...data} />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
}
