import { Container  } from "reactstrap";
import MainPostDataLayout from "components/MainPostDataLayout";


export default function Home() {
  

  return (
    <section className="py-5">
      <Container>

        <MainPostDataLayout />
      </Container>
    </section>
  );
}
