import { Input, Label } from "reactstrap";
import cx from "classnames";

export default function MainInput(props) {
  const {
    className = "",
    type = "text",
    name = "",
    label = "",
    placeholder = "",
    value,
    onChange = () => {},
    options = [],
    maxLength = null,
    style,
    ...rest
  } = props;

  const inputProps = {
    type,
    name,
    value,
    placeholder,
    onChange: (e) => onChange(name, e.target.value),
    ...rest,
    maxLength,
    style,
  };

  function renderInput() {
    if (type === "radio") {
      return (
        <Label>
          <Input {...inputProps} />
          {label && <span className="ms-2">{label}</span>}
        </Label>
      );
    }
    if (type === "select") {
      return (
        <>
          {label && <Label>{label}</Label>}

          <Input {...inputProps}>
            <option value={null}>{placeholder}</option>
            {options.map((data, index) => (
              <option key={"select-options" + index} value={data?.value}>
                {data?.name}
              </option>
            ))}
          </Input>
        </>
      );
    }

    return (
      <>
        {label && <Label>{label}</Label>}
        <Input {...inputProps} />
      </>
    );
  }

  return <div className={cx("main-input", className)}>{renderInput()}</div>;
}
