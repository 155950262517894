import React, { useState } from "react";
import Select, { components } from "react-select";

const EMOJIS = ["📍"];

const Control = ({ children, ...props }) => {
  // @ts-ignore
  const { emoji, onEmojiClick } = props.selectProps;
  const style = { cursor: "pointer" };

  return (
    <components.Control {...props}>
      <span onMouseDown={onEmojiClick} style={style}>
        {emoji}
      </span>
      {children}
    </components.Control>
  );
};

export default function PrimaryLocation(props) {
  const { label = "Primary Location", placeholder = "" } = props;
  const [clickCount, setClickCount] = useState(0);

  const onClick = (e) => {
    setClickCount(clickCount + 1);
    e.preventDefault();
    e.stopPropagation();
  };

  const styles = {
    control: (css) => ({ ...css, paddingLeft: "1rem" }),
  };

  const emoji = EMOJIS[clickCount % EMOJIS.length];

  return (
    <div className="main-input">
      {label && <label className="form-label">{label}</label>}
      <Select
        {...props}
        // @ts-ignore
        emoji={emoji}
        placeholder={placeholder}
        classNamePrefix="theme-react-select"
        onEmojiClick={onClick}
        components={{ Control }}
        isSearchable
        name="emoji"
        options={[
          { value: "Location 1", label: "location-1" },
          { value: "Location 2", label: "location-2" },
          { value: "Location 3", label: "location-3" },
        ]}
        styles={styles}
      />
    </div>
  );
}
