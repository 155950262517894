import { useState } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useAuthValue } from "shared/AuthContext";
import { auth } from "firebase-config";
import LoginSignUpLayout from "components/LoginSignUpLayout";
import facebookIcon from "assets/images/social-icons/facebook.svg";
import googleIcon from "assets/images/social-icons/google.svg";
import { Button, Alert,Input } from "reactstrap";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { signInWithGoogle,login, } = useAuthValue();
  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();


  const loginCumSignUp = async() =>{
    try {
      setLoading(true)
      await signInWithGoogle()
      // navigate('/')
      
    }catch(error){
      setError(error.message)
      console.log('error',error)
    }
    setLoading(false)

  }


  // Social Media Button
  const socialLogin = [
    {
      icon: googleIcon,
      name: "Continue with Google",
      onClick:  () =>loginCumSignUp()
    },
    { icon: facebookIcon, name: "Continue with Facebook" },
    // { icon: linkedinIcon, name: "Sign Up with LinkedIn" },
    // { icon: twitterIcon, name: "Sign Up with Twitter" },
  ];

  //Using Email
  const loginHandler =  async(e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);
      await login(email, password)
      // authStateChanged()
      .then(data =>{
        if(data){

          setTimeout(()=>{
            navigate('/')   
          },500)
        }

      })

    }
    catch(e){
      setError(e.message)
      console.log('eee',e.message)
    }
    setLoading(false);


  };

  return (
    <LoginSignUpLayout>
      <div className="center">
        <div className="auth">
          <h3 className="mb-4">Welecome Back</h3>
          <p className="text">Search, Find & Apply jobs from anywhere</p>

          {error && <Alert variant={'danger'}> {error} </Alert>}
          <form onSubmit={loginHandler} name="login_form">
            <Input
              type="email"
              value={email}
              required
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <Input
              type="password"
              value={password}
              required
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button disabled ={loading} color="primary" type="submit" className="w-100 mb-3">
              Login
            </Button>
          </form>

          <div>
            {socialLogin.map(({ name, icon, onClick }, index) => (
              <Button
                disabled ={loading}
                variant="light"
                key={index}
                className="social-login-btn"
                onClick={onClick}
              >
                <img src={icon} alt={name} className="me-3" />
                {name}
              </Button>
            ))}
          </div>
          <p className="divider-form text-gray">
            <span>or</span>
          </p>
          <p>
            Don't have and account?
            <Button variant="light" type="submit" className="w-100 mb-3">
              <Link to="/sign-up">Register</Link>
            </Button>
          </p>
        </div>
      </div>
    </LoginSignUpLayout>
  );
}
