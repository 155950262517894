import { Button } from "reactstrap";
import facebookIcon from "assets/images/social-icons/facebook.svg";
import googleIcon from "assets/images/social-icons/google.svg";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "firebase-config";
import { useNavigate } from "react-router-dom";
import { useAuthValue } from "shared/AuthContext";
import { ProfileType } from "pages";

export default function SocialSignUpForm(props) {
  const navigate = useNavigate();
  const { userConfig } = useAuthValue();
  //auth
  // const provider = new GoogleAuthProvider();
  // auth.languageCode = "it";

  // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  // provider.setCustomParameters({
  //   login_hint: "localhost:3000",
  // });

  const { children } = props;
  
  // const onPressGoogleSignHandler = () => {
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       const credential = GoogleAuthProvider.credentialFromResult(result);
  //       console.log("** credential **", credential);
  //       const token = credential.accessToken;
  //       console.log("** token **", token);
  //       // The signed-in user info.
  //       const user = result.user;
  //       console.log("** user **", user);

  //       navigate(userConfig && userConfig?.profileType ? "/" : "/profile-type");
  //       // ...
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       console.warn("** error **", error);
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       // The email of the user's account used.
  //       const email = error.customData.email;
  //       // The AuthCredential type that was used.
  //       const credential = GoogleAuthProvider.credentialFromError(error);
  //       // ...
  //     });
  // };

  return (
    <div className="shadow p-5 mb-15 bg-body rounded">

    <div className="form-wrapper">
      {/* <h1 className="title">Sign Up for an account</h1>
      
      {/* <p className="divider-form text-gray">
        <span>or with email</span>
      </p> */}
      {children}
    </div>
    </div>
  );
}
